import React, { useEffect, useState, useMemo } from "react";
import axios from "../../axios";
import styled from "styled-components";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import { useTable, usePagination } from "react-table";
import CommentModal from "../../components/Modal/CommentModal";

const Container = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    margin-top: 10px;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data, pageCount, index }) {
  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      index,
      pageCount,
      manualPagination: true,
      initialState: { pageIndex: index - 1 },
    },
    usePagination
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const FirstPage = () => {
    gotoPage(0);
    const categoryParam = searchParams.get("category");
    setSearchParams({ page: 1, category: categoryParam });
  };

  const PrevPage = () => {
    previousPage();
    const pageParam = parseInt(searchParams.get("page")) - 1;
    const categoryParam = searchParams.get("category");
    setSearchParams({ page: pageParam, category: categoryParam });
  };

  const NextPage = () => {
    nextPage();
    const pageParam = parseInt(searchParams.get("page")) + 1;
    const categoryParam = searchParams.get("category");
    setSearchParams({ page: pageParam, category: categoryParam });
  };

  const LastPage = () => {
    gotoPage(pageCount - 1);
    const categoryParam = searchParams.get("category");
    setSearchParams({ page: pageCount, category: categoryParam });
  };

  return (
    <>
      <table>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={FirstPage} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button onClick={PrevPage} disabled={!canPreviousPage}>
          {"<"}
        </button>
        <button onClick={NextPage} disabled={!canNextPage}>
          {">"}
        </button>
        <button onClick={LastPage} disabled={!canNextPage}>
          {">>"}
        </button>
        <span>
          Page{" "}
          <strong>
            {index} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div>
    </>
  );
}

function Overdue() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [index, setIndex] = useState(searchParams.get("page"));

  const categoryStatus = [
    { name: "30 хоног", status: 0 },
    { name: "90 хоног", status: 1 },
    { name: "120 хоног", status: 2 },
    { name: "360 хоног", status: 3 },
    { name: "360-с дээш хоног", status: 4 },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "loan_id",
      },
      {
        Header: "Нэр",
        accessor: "name",
        Cell: (params) => {
          return (
            <Link
              to={{
                pathname: `/detail`,
                search: `query=${params.row.original.user_public_id}`,
              }}
              type="button"
            >
              {params.row.original.name}
            </Link>
          );
        },
      },
      {
        Header: "Утас",
        accessor: "phone_number",
      },
      {
        Header: "Үндсэн зээл",
        accessor: "loan_amount",
      },
      {
        Header: "Төлөх дүн",
        accessor: "payment_amount",
      },
      {
        Header: "Олгосон огноо",
        accessor: (d) => d?.issue_date?.replace("T", " "),
      },
      {
        Header: "Төлөх огноо",
        accessor: (d) => d?.payment_date?.replace("T", " "),
      },
      {
        Header: () => <i className="bi bi-clock" />,
        accessor: "late_day",
      },
      {
        Header: () => <i className="bi bi-person-lines-fill" />,
        accessor: "comment",
        Cell: (params) => {
          return (
            <Link
              to={{
                pathname: `/loan-comment`,
                search: `query=${params.row.original.user_public_id}`,
              }}
              type="button"
              className="bi bi-person-lines-fill"
            />
          );
        },
      },
      {
        Header: () => <i className="bi bi-telephone-fill" />,
        accessor: "modal",
        Cell: (type) => {
          const name = type?.row?.original?.name;
          const loan_id = type?.row?.original?.loan_id;
          const publicId = type?.row?.original?.user_public_id;
          return (
            <CommentModal loanId={loan_id} name={name} publicId={publicId} />
          );
        },
      },
      {
        Header: "Сүүлд Залгасан",
        accessor: (d) => d?.latest_comment?.created_at?.replace("T", " "),
      },
      {
        Header: "Холбогдсон Эсэх",
        accessor: "latest_comment.connection_status",
        Cell: (type) => {
          const connection_status =
            type?.row?.original?.latest_comment?.connection_status;
          return (
            <div>
              {connection_status === 0
                ? "Утсаа авсан"
                : connection_status === 1
                ? "Утсаа аваагүй"
                : connection_status === 2
                ? "Холбогдохгүй боломжгүй"
                : connection_status === 3
                ? "Утсаа тасалсан"
                : connection_status === 4
                ? "Ашиглалтанд байхгүй"
                : connection_status === 5
                ? "Ярианы эрх хаагдсан"
                : connection_status === 6
                ? "Өөр хүн авсан"
                : null}
            </div>
          );
        },
      },
      {
        Header: "Text",
        accessor: "latest_comment.text",
      },
    ],
    []
  );

  const SetCategory = () => {
    const pageParam = searchParams.get("page");
    const categoryParam = searchParams.get("category");
    setSearchParams({ page: pageParam, category: categoryParam });
  };

  useEffect(() => {
    const pageParam = searchParams.get("page");
    const categoryParam = searchParams.get("category");
    setIndex(pageParam);

    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/overdue_loans?page=${pageParam}&category=${categoryParam}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      setUsers(response.data.overdues);
      setTotalPages(response.data.pages);
    };
    fetchData();
  }, [location.search]);

  return (
    <Container>
      <select
        className="form-select form-select-lg w-25 mb-3"
        onChange={(e) => {
          const pageParam = searchParams.get("page");
          setSearchParams({
            page: pageParam,
            category: parseInt(e.target.value),
          });
        }}
      >
        {categoryStatus?.map((status, index) => {
          return (
            <option value={status.status} key={index}>
              {status.name}
            </option>
          );
        })}
      </select>

      <Table
        index={index}
        columns={columns}
        pageCount={totalPages}
        data={users ? users : []}
      />
    </Container>
  );
}

export default Overdue;
