import React, { useEffect } from "react";
import { useLocation, useNavigate, Navigate, Outlet } from "react-router-dom";

function isTokenExpired() {
  var expirationDate = JSON.stringify(
    new Date(localStorage.getItem("expires_at"))
  );
  expirationDate = expirationDate.replace("T", " ");
  expirationDate = expirationDate.slice(1, 20);

  var currentDate = JSON.stringify(new Date());
  currentDate = currentDate.replace("T", " ");
  currentDate = currentDate.slice(1, 20);

  return expirationDate < currentDate;
}

const PrivateRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) {
      alert("Шинээр нэвтрэнэ үү");
      return navigate("/login");
    }
  }, [location]);

  const token = localStorage.getItem("token");
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
