import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import styled from "styled-components";
import axios from "axios";
import "./styles.css";

const Container = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    margin-top: 10px;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Button = styled.button`
  background-color: #ffffff;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  color: #111827;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  :hover {
    background-color: #f9fafb;
  }

  :focus {
    outline: 2px solid rgba(0, 0, 0, 0.1);
    outline-offset: 2px;
  }

  :focus-visible {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

const Suggestion = () => {
  const params = useLocation();
  const account = params.state.account;
  const text = params.state.suggestion_text;
  const amount = params.state.amount;
  const transaction_id = params.state.id;
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const columns = [
    {
      Header: "Овог",
      accessor: "detail.last_name",
    },
    {
      Header: "Нэр",
      accessor: "detail.first_name",
    },
    {
      Header: "Регистерийн дугаар",
      accessor: "register_number",
    },
    {
      Header: "Утасны дугаар",
      accessor: "phone_number",
    },
    {
      Header: "Төлбөрийг хаах",
      accessor: "",
      Cell: (params) => {
        const handleDetailClick = () => {
          const fetchData = async () => {
            const token = localStorage.getItem("token");
            await axios.get(
              `${process.env.REACT_APP_BASE_URL}/add_balance?user_id=${params.row.original.id}&amount=${amount}&transaction_id=${transaction_id}`,
              { headers: { Authorization: "Bearer " + token } }
            );
          };

          if (window.confirm("Гүйлгээг хаах уу?")) {
            fetchData();
            navigate(-1);
          }
        };
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleDetailClick}>Хэтэвч руу шилжүүлэх</Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/suggest?text=${text}&account=${account}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      setUsers(response.data.users);
    };
    fetchData();
  }, [token, text, account]);

  function Table({ columns, data }) {
    const { headerGroups, rows, prepareRow } = useTable({
      columns,
      data,
    });

    return (
      <>
        <table>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  return (
    <Container>
      <Table columns={columns} data={users ? users : []} />
    </Container>
  );
};

export default Suggestion;
