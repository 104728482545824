import React, { useState } from "react";
import axios from "axios";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "14%",
  },
};

Modal.setAppElement(document.getElementById("root"));

const ContractModal = ({ user_id }) => {
  const token = localStorage.getItem("token");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [contract_number, setContractNumber] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/contract`,
        { contract_number, user_id },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, "2000");
      });

    closeModal();
  };

  return (
    <div>
      <button
        onClick={openModal}
        type="button"
        className="btn btn-sm btn-secondary m-1"
      >
        Гэрээ байгуулах
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="h-100 d-flex flex-column justify-content-between">
          <div className="d-flex flex-column w-100">
            <label>Гэрээ байгуулах</label>
            <input
              className="form-control"
              value={contract_number}
              onChange={(e) => setContractNumber(e.target.value)}
              placeholder="Гэрээний дугаар"
            />
          </div>
          <div>
            <button
              onClick={onSubmit}
              type="button"
              className="btn btn-sm btn-primary"
            >
              Баталгаажуулах
            </button>
            <button
              onClick={closeModal}
              type="button"
              className="btn btn-sm btn-secondary m-2"
            >
              Хаах
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ContractModal;
