import React from "react";
import axios from "axios";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import { mainNavbarItems } from "./consts/navbarItems";
import { navbarStyles } from "./styles";
import { useNavigate } from "react-router";

const Navbar = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleLogOut = () => {
    const fetchData = async () => {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/logout`, {
        headers: { Authorization: "Bearer " + token },
      });
    };
    fetchData();
    navigate("/login");
  };

  return (
    <Drawer sx={navbarStyles.drawer} variant="permanent" anchor="left">
      <Toolbar />
      <Divider />
      <List
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          {mainNavbarItems.map((item, index) => (
            <ListItem key={item.id} onClick={() => navigate(item.route)}>
              <ListItemButton>
                <ListItemIcon sx={navbarStyles.icons}>{item.icon}</ListItemIcon>
                <ListItemText sx={navbarStyles.text} primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </div>
        <div>
          <ListItem onClick={() => handleLogOut()}>
            <ListItemButton>
              <ListItemIcon sx={navbarStyles.icons}>
                {<LogoutIcon />}
              </ListItemIcon>
              <ListItemText sx={navbarStyles.text} primary={"Гарах"} />
            </ListItemButton>
          </ListItem>
        </div>
      </List>
    </Drawer>
  );
};

export default Navbar;
