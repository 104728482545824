import React, { useState } from "react";
import axios from "axios";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "14%",
  },
};

Modal.setAppElement(document.getElementById("root"));

const PocketModal = ({ user_public_id }) => {
  const token = localStorage.getItem("token");
  const [amount, setAmount] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = () => {
    const fetchData = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/pocket`,
          { amount, user_public_id },
          {
            headers: { Authorization: "Bearer " + token },
          }
        )
        .then(() => {
          setIsOpen(false);
        });
    };
    fetchData();
  };

  return (
    <div>
      <button
        onClick={openModal}
        type="button"
        className="btn btn-sm btn-primary"
      >
        Pocket
      </button>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <div className="h-100 d-flex flex-column justify-content-between">
          <div className="d-flex flex-column w-100">
            <label>Pocket</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(parseInt(e.target.value))}
              className="form-control"
              placeholder="Дүн"
            />
          </div>
          <div>
            <button
              type="button"
              onClick={onSubmit}
              className="btn btn-sm btn-primary"
            >
              Баталгаажуулах
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-sm btn-secondary m-2"
            >
              Хаах
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PocketModal;
