import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams, Link } from "react-router-dom";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import axios from "axios";

const Container = styled.div`
  padding: 10px;

  table {
    width: 100%;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 5px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Button = styled.button`
  background-color: #ffffff;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  color: #111827;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  :hover {
    background-color: #f9fafb;
  }

  :focus {
    outline: 2px solid rgba(0, 0, 0, 0.1);
    outline-offset: 2px;
  }

  :focus-visible {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

function Table({ columns, data, pageCount }) {
  const { headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      pageCount,
      manualPagination: true,
    },
    usePagination
  );

  return (
    <>
      <table>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const ActiveLoan = () => {
  const [searchParams] = useSearchParams();
  const publicId = searchParams.get("query");
  const token = localStorage.getItem("token");
  const role = parseInt(localStorage.getItem("role"));
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: 50,
      },
      {
        Header: "Олгосон зээл",
        accessor: "amount",
        width: 50,
      },
      {
        Header: "Үндсэн зээлийн үлдэгдэл",
        accessor: "balance",
        width: 50,
      },
      {
        Header: "Нийт Бодогдсон хүү	",
        accessor: "interest_amount",
        width: 50,
      },
      {
        Header: "Олгосон огноо",
        accessor: (d) => d?.start_date?.replace("T", " "),
        width: 50,
      },
      {
        Header: "Дуусах огноо",
        accessor: (d) => d?.due_date?.replace("T", " "),
        width: 50,
      },
      {
        Header: "Дараагийн төлөлт",
        accessor: (d) => d?.next_payment_date?.replace("T", " "),
        width: 50,
      },
      {
        Header: "Сунгагдсан эсэх",
        accessor: (r) => r?.is_extension?.toString(),
        width: 50,
      },
      {
        Header: "Дэлгэрэнгүй",
        accessor: "more",
        width: 50,
        Cell: (params) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to={{
                  pathname: `/loan-info`,
                  search: `query=${params.row.original.id}`,
                }}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Button>Дэлгэрэнгүй</Button>
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Төлбөр төлөх",
        accessor: "register_number",
        width: 50,
        Cell: (params) => {
          return role ? (
            <></>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to={{
                  pathname: `/loan-payment`,
                  search: `query=${params.row.original.id}`,
                }}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Button>Төлбөр төлөх</Button>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/loans?public_id=${publicId}&is_active=true`,
        { headers: { Authorization: "Bearer " + token } }
      );
      setData(response.data);
    };
    fetchData();
  }, [publicId, token]);

  return (
    <>
      <div className="d-flex" style={{ fontSize: 14 }}>
        <div className="card bg-light w-100 m-3">
          <h3 className="card-header">Идэвхитэй зээлүүд</h3>
          <div className="card-body p-0">
            <Container>
              <Table columns={columns} data={data ? data : []} />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveLoan;
