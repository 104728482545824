import React, { useState } from "react";
import axios from "axios";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    padding: 0,
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
  },
};

Modal.setAppElement(document.getElementById("root"));

const CommentModal = ({ loanId, name, publicId }) => {
  const token = localStorage.getItem("token");

  const connectionStatus = [
    { name: "Утсаа авсан", status: 0 },
    { name: "Утсаа аваагүй", status: 1 },
    { name: "Холбогдохгүй боломжгүй", status: 2 },
    { name: "Утсаа тасалсан", status: 3 },
    { name: "Ашиглалтанд байхгүй", status: 4 },
    { name: "Ярианы эрх хаагдсан", status: 5 },
    { name: "Өөр хүн авсан", status: 6 },
  ];

  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({
    text: "",
    loan_id: loanId,
    connection_status: 0,
    user_public_id: publicId,
  });

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = () => {
    const fetchData = async () => {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/comments`, data, {
          headers: { Authorization: "Bearer " + token },
        })
        .then(() => {
          setIsOpen(false);
        });
    };
    fetchData();
  };

  return (
    <div>
      <div
        type="button"
        onClick={openModal}
        style={{ color: "#0d6efd" }}
        className="bi bi-telephone-fill"
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="card bg-light">
          <h3 className="card-header">{name}-д зээлийн коммент үүсгэх</h3>
          <div className="card-body p-0">
            <div className="p-3">
              <div className="d-flex flex-column w-100 mb-3">
                <label className="mb-1">Статус</label>
                <select
                  className="form-select"
                  onChange={(e) =>
                    setData({
                      ...data,
                      connection_status: parseInt(e.target.value),
                    })
                  }
                >
                  {connectionStatus?.map((status, index) => {
                    return (
                      <option value={status.status} key={index}>
                        {status.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="d-flex flex-column w-100 mb-3">
                <label className="mb-1">Зурвас</label>
                <textarea
                  placeholder="Зурвас"
                  className="form-control"
                  onChange={(e) => setData({ ...data, text: e.target.value })}
                />
              </div>
              <button
                onClick={onSubmit}
                type="button"
                className="btn btn-primary w-100"
              >
                Yүсгэх
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CommentModal;
