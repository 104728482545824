import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import axios from "axios";

const Container = styled.div`
  padding: 10px;

  table {
    width: 100%;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 5px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data, pageCount, setPage }) {
  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      pageCount,
      manualPagination: true,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  useEffect(() => {
    setPage(pageIndex + 1);
  }, [pageIndex, setPage]);

  return (
    <>
      <table>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div>
    </>
  );
}

const UserHistory = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("query");
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: "Нэвтэрсэн огноо",
        accessor: (d) => d?.created_at?.replace("T", " "),
        width: 50,
      },
      {
        Header: "OS Version",
        accessor: "os_version",
        width: 50,
      },
      {
        Header: "Device model",
        accessor: "device_model",
        width: 50,
      },
      {
        Header: "Device name",
        accessor: "device_name",
        width: 50,
      },
      {
        Header: "App version",
        accessor: "app_version",
        width: 50,
      },
      {
        Header: "Сүлжээ",
        accessor: "network",
        width: 50,
      },
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user_devices?user_id=${id}&page=${page}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      setData(response.data.users);
      setTotalPages(response.data.pages);
    };
    fetchData();
  }, [page, id, token]);

  return (
    <>
      <div className="d-flex" style={{ fontSize: 14 }}>
        <div className="card bg-light w-100 m-3">
          <h3 className="card-header">Нэвтэрсэн төхөөрөмжүүд</h3>
          <div className="card-body p-0">
            <Container>
              <Table
                columns={columns}
                data={data ? data : []}
                pageCount={totalPages}
                setPage={setPage}
              />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHistory;
