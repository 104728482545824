import GroupIcon from "@mui/icons-material/Group";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

export const mainNavbarItems = [
  {
    id: 0,
    icon: <GroupIcon />,
    label: "Хэрэглэгч",
    route: "/",
  },
  {
    id: 1,
    icon: <FormatListBulletedIcon />,
    label: "Хугацаа хэтэрсэн зээл",
    route: "/overdue?page=1&category=0",
  },
  {
    id: 2,
    icon: <AccountBalanceIcon />,
    label: "Танигдаагүй гүйлгээ",
    route: "/unknown-transactions",
  },
  {
    id: 3,
    icon: <VerifiedUserIcon />,
    label: "Баталгаажуулах хэрэглэгчид",
    route: "/verified-users",
  },
];
