import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const ClosedLoan = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("query");
  const token = localStorage.getItem("token");

  return (
    <div className="d-flex justify-content-center" style={{ fontSize: 14 }}>
      <div className="card bg-light w-25 m-3">
        <h3 className="card-header">Хаагдсан зээл</h3>
        <div className="card-body p-0">
          <div className="p-3">
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between m-1">
              <label className="mb-1">Зээлийн дугаар</label>
              <div>{103010103}</div>
            </div>
            <hr className="m-0" />
            <button
              onClick={() => console.log("this fires on the first button")}
              type="button"
              className="btn btn-primary btn-sm w-100 mb-2"
            >
              Төлөх
            </button>
            <button
              onClick={() => console.log("this fires on the second button")}
              type="button"
              className="btn btn-primary btn-sm w-100 mb-2"
            >
              Төлөх
            </button>
            <button
              onClick={() => console.log("this fires on the third button")}
              type="button"
              className="btn btn-primary btn-sm w-100 mb-2"
            >
              Төлөх
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClosedLoan;
