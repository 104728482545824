import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import styled from "styled-components";
import axios from "axios";
import "./styles.css";

const Button = styled.button`
  background-color: #ffffff;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  color: #111827;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  :hover {
    background-color: #f9fafb;
  }

  :focus {
    outline: 2px solid rgba(0, 0, 0, 0.1);
    outline-offset: 2px;
  }

  :focus-visible {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

const Verify = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const token = localStorage.getItem("token");

  const rowData = params.state;
  const id = rowData.id;
  const backImage = rowData.back_image;
  const frontImage = rowData.front_image;
  const selfieImage = rowData.selfie_image;
  const image = [
    { title: "Back image", url: backImage },
    { title: "Front image", url: frontImage },
    { title: "Selfie image", url: selfieImage },
  ];

  const handleClick = () => {
    const fetchData = async () => {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/re_verify?id=${id}`, {
        headers: { Authorization: "Bearer " + token },
      });
    };

    if (window.confirm("Хэрэглэгчийг баталгаажуулах уу?")) {
      fetchData();
      navigate(-1);
    }
  };

  return (
    <div>
      <div className="image-container">
        <img src={backImage} onClick={() => setIsOpen(true)} />
        <img src={frontImage} onClick={() => setIsOpen(true)} />
        <img src={selfieImage} onClick={() => setIsOpen(true)} />
        {isOpen && (
          <Lightbox
            imageTitle={image[imgIndex].title}
            imageCaption={image[imgIndex].caption}
            mainSrc={image[imgIndex].url}
            nextSrc={image[(imgIndex + 1) % image.length].url}
            prevSrc={image[(imgIndex + image.length - 1) % image.length].url}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setImgIndex((imgIndex + image.length - 1) % image.length)
            }
            onMoveNextRequest={() => setImgIndex((imgIndex + 1) % image.length)}
          />
        )}
      </div>
      <div>
        <Button onClick={handleClick}>Батлах</Button>
      </div>
    </div>
  );
};

export default Verify;
