import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const Layout = () => (
  <div>
    <div style={{ position: "fixed", top: 0, left: 0 }}>
      <Navbar />
    </div>
    <div style={{ marginLeft: 320 }}>
      <Outlet />
    </div>
  </div>
);

export default Layout;
