import React, { useEffect, useState, useMemo } from "react";
import axios from "../../axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTable, usePagination } from "react-table";

const Container = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    margin-top: 10px;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Button = styled.button`
  background-color: #ffffff;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  color: #111827;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  :hover {
    background-color: #f9fafb;
  }

  :focus {
    outline: 2px solid rgba(0, 0, 0, 0.1);
    outline-offset: 2px;
  }

  :focus-visible {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

function Table({ columns, data, pageCount, setPage }) {
  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      pageCount,
      manualPagination: true,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  useEffect(() => {
    setPage(pageIndex + 1);
  }, [pageIndex, setPage]);

  return (
    <>
      <table>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div>
    </>
  );
}

function UnknownTransactions() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const token = localStorage.getItem("token");

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Санал болгосон текст",
        accessor: "suggestion_text",
      },
      {
        Header: "Санал болгосон төрөл",
        accessor: "suggestion_type",
      },
      {
        Header: "Дүн",
        accessor: "amount",
      },
      {
        Header: "Гүйлгээний утга",
        accessor: "description",
      },
      {
        Header: "Төлбөрийн огноо",
        accessor: "payment_date",
      },
      {
        Header: "Дансны дугаар",
        accessor: "account",
      },
      {
        Header: "Санал болгох",
        accessor: "more",
        Cell: (params) => {
          const handleDetailClick = () => {
            navigate("/suggestion", { state: params.row.original });
          };
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={handleDetailClick}>Дэлгэрэнгүй</Button>
            </div>
          );
        },
      },
      {
        Header: "Төлбөрийг хаах",
        accessor: "",
        Cell: (params) => {
          const handleDetailClick = () => {
            const fetchData = async () => {
              const token = localStorage.getItem("token");
              await axios.get(
                `${process.env.REACT_APP_BASE_URL}/close_transaction?id=${params.row.original.id}`,
                { headers: { Authorization: "Bearer " + token } }
              );
            };

            if (window.confirm("Гүйлгээг хаах уу?")) {
              fetchData();
              setTimeout(function () {
                window.location.reload();
              }, 1500);
            }
          };
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={handleDetailClick}>Хаах</Button>
            </div>
          );
        },
      },
    ],
    [navigate]
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/pending_transactions?page=${page}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      setTransactions(response.data.pending_transactions);
      setTotalPages(response.data.pages);
    };
    fetchData();
  }, [page, token]);

  return (
    <Container>
      <Table
        columns={columns}
        data={transactions ? transactions : []}
        pageCount={totalPages}
        setPage={setPage}
      />
    </Container>
  );
}

export default UnknownTransactions;
