import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Overdue from "./pages/Overdue/Overdue";
import Layout from "./components/Navbar/Layout";
import UnknownTransactions from "./pages/UnknownTransactions/UnknownTransactions";
import Users from "./pages/Users/Users";
import Detail from "./pages/Detail/Detail";
import Verify from "./pages/Verify/Verify";
import VerifiedUsers from "./pages/VerifiedUsers/VerifiedUsers";
import PrivateRoutes from "./components/PrivateRoute";
import Grid from "@mui/material/Grid";
import Suggestion from "./pages/Suggestion/Suggestion";
import InsuranceStatement from "./pages/Statement/InsuranceStatement";
import BankStatement from "./pages/Statement/BankStatement";
import ActiveLoan from "./pages/Loan/ActiveLoan";
import LoanInfo from "./pages/Loan/LoanInfo";
import LoanPayment from "./pages/Loan/LoanPayment";
import LoanComment from "./pages/Loan/LoanComment";
import LoanHistory from "./pages/Loan/LoanHistory";
import UserDevice from "./pages/UserDevice/UserDevice";
import EditInfo from "./pages/EditInfo/EditInfo";
import ClosedLoan from "./pages/Loan/ClosedLoan";
import Pocket from "./pages/Pocket/Pocket";

function App() {
  return (
    <Grid>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Users />} />
              <Route path="/overdue" element={<Overdue />} />
              <Route
                path="/unknown-transactions"
                element={<UnknownTransactions />}
              />
              <Route path="/suggestion" element={<Suggestion />} />
              <Route path="/verified-users" element={<VerifiedUsers />} />

              <Route path="/detail" element={<Detail />} />
              <Route path="/verify/:id" element={<Verify />} />
              <Route
                path="/insurance-statement"
                element={<InsuranceStatement />}
              />
              <Route path="/bank-statement" element={<BankStatement />} />
              <Route path="/active-loan" element={<ActiveLoan />} />
              <Route path="/loan-info" element={<LoanInfo />} />
              <Route path="/loan-payment" element={<LoanPayment />} />
              <Route path="/loan-comment" element={<LoanComment />} />
              <Route path="/loan-history" element={<LoanHistory />} />
              <Route path="/closed-loan" element={<ClosedLoan />} />
              <Route path="/user-device" element={<UserDevice />} />
              <Route path="/edit-info" element={<EditInfo />} />
              <Route path="/pocket" element={<Pocket />} />
            </Route>
          </Route>
          <Route path={"/login"} element={<Login />} />
        </Routes>
      </Router>
    </Grid>
  );
}

export default App;
