import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // axios send request
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/login`,
        { username, password }
      );
      if (response.data.status) {
        const expirationDate = new Date(
          Date.now() + response.data.expires_in * 10
        );
        localStorage.setItem("role", response.data.user_info.role);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("expires_at", expirationDate);
        navigate("/");
      } else {
        alert("Хэрэглэгчийн мэдээлэл буруу байна.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="vh-100 vw-100" onSubmit={handleSubmit}>
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <h3>Cash One</h3>
        <input
          type="text"
          className="form-control w-25 mb-2"
          placeholder="Username"
          aria-label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          className="form-control w-25 mb-2"
          placeholder="Password"
          aria-label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="btn btn-primary w-25">
          Login
        </button>
      </div>
    </form>
  );
}

export default Login;
