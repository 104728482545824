import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import axios from "axios";

const Container = styled.div`
  padding: 10px;

  table {
    width: 100%;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 15px 5px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data, pageCount }) {
  const { headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      pageCount,
      manualPagination: true,
    },
    usePagination
  );

  return (
    <>
      <table>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const LoanInfo = () => {
  const [searchParams] = useSearchParams();
  const loanId = searchParams.get("query");
  const token = localStorage.getItem("token");
  const [data, setData] = useState();

  const loanColumns = useMemo(
    () => [
      {
        Header: "Зээлийн дугаар	",
        accessor: "id",
      },
      {
        Header: "Олгосон зээл	",
        accessor: "amount",
      },
      {
        Header: "Олгосон огноо	",
        accessor: (d) => d?.start_date?.replace("T", " "),
      },
      {
        Header: "Дуусах огноо	",
        accessor: (d) => d?.due_date?.replace("T", " "),
      },
      {
        Header: "Үндсэн зээлийн үлдэгдэл	",
        accessor: "balance",
      },
      {
        Header: "Тооцоолсон огноо	",
        accessor: (d) => d?.next_payment_date?.replace("T", " "),
      },
      {
        Header: "Хурамтлагдсан хүү	",
        accessor: "interest_amount",
      },
      {
        Header: "Шимтгэлийн үлдэгдэл	",
        accessor: "fee_amount",
      },
      {
        Header: "Хэтэрсэн хоног	",
        accessor: "late_day",
      },
      {
        Header: "Нийт төлөх дүн	",
        accessor: "total_pay_amount",
      },
    ],
    []
  );

  const scheduleColumns = useMemo(
    () => [
      {
        Header: "Төлөлтийн дугаар	",
        accessor: "id",
      },
      {
        Header: "Үндсэн зээлийн эхний үлдэгдэл	",
        accessor: "beginning_balance",
      },
      {
        Header: "Үндсэн зээлэнд",
        accessor: "principal_amount",
      },
      {
        Header: "Хуваарийн дагуу бодогдох хүү	",
        accessor: "interest_amount",
      },
      {
        Header: "Бодогдсон хүү",
        accessor: "interest_balance",
      },
      {
        Header: "Төлөх дүн	",
        accessor: "payment_amount",
      },
      {
        Header: "Төлбөр төлөх огноо	",
        accessor: (d) => d?.payment_date?.replace("T", " "),
      },
      {
        Header: "Үндсэн зээлийн эцсийн үлдэгдэл	",
        accessor: "ending_balance",
      },
      {
        Header: "Төлөгдсөн эсэх	",
        accessor: (r) => r?.is_paid?.toString(),
      },
    ],
    []
  );

  const paymentColumns = useMemo(
    () => [
      {
        Header: "fee_amount	",
        accessor: "fee_amount",
      },
      {
        Header: "payment_date	",
        accessor: (d) => d?.payment_date?.replace("T", " "),
      },
      {
        Header: "interest_amount",
        accessor: "interest_amount",
      },
      {
        Header: "principal_amount	",
        accessor: "principal_amount",
      },
      {
        Header: "total_amount	",
        accessor: "total_amount",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/loan_detail?loan_id=${loanId}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      response.data.loan_info = [response.data.loan_info];
      setData(response.data);
    };
    fetchData();
  }, [loanId, token]);

  const extendLoan = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/send_extension_request?loan_id=${loanId}`,
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((response) => {
        window.alert(response.data.message);
      });
  };

  return (
    <>
      <div className="d-flex" style={{ fontSize: 14 }}>
        <div className="card bg-light w-100 m-3">
          <h3 className="card-header">Төлөлтийн хуваарь</h3>
          <div className="card-body p-0">
            <Container>
              <div className="my-3">
                <h5>Loan info</h5>
                {data?.loan_info[0]?.is_extend ? (
                  <button
                    onClick={extendLoan}
                    type="button"
                    className="btn btn-secondary btn-sm mb-2"
                  >
                    Зээл сунгах
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm mb-2 disabled"
                  >
                    Зээл сунгах
                  </button>
                )}
                <Table
                  columns={loanColumns}
                  data={data ? data.loan_info : []}
                />
              </div>
              <div className="my-3">
                <h5>Schedule info</h5>
                <Table
                  columns={scheduleColumns}
                  data={data ? data.schedule_info : []}
                />
              </div>
              <div className="my-3">
                <h5>Payment info</h5>
                <Table
                  columns={paymentColumns}
                  data={data ? data.payment_info : []}
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanInfo;
