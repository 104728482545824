import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

const InsuranceStatement = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("query");
  const token = localStorage.getItem("token");
  const [rerender, setRerender] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [statement, setStatement] = useState([
    {
      date_from: "",
      date_to: "",
      amount: null,
    },
    {
      date_from: "",
      date_to: "",
      amount: null,
    },
    {
      date_from: "",
      date_to: "",
      amount: null,
    },
    {
      date_from: "",
      date_to: "",
      amount: null,
    },
    {
      date_from: "",
      date_to: "",
      amount: null,
    },
    {
      date_from: "",
      date_to: "",
      amount: null,
    },
  ]);

  useEffect(() => {
    statement.map((item, index) => {
      var date = new Date();
      date.setMonth(date.getMonth() - index);

      var firstDay = JSON.stringify(
        new Date(date.getFullYear(), date.getMonth(), 2)
      );
      var lastDay = JSON.stringify(
        new Date(date.getFullYear(), date.getMonth() + 1)
      );
      firstDay = firstDay.slice(1, 11);
      lastDay = lastDay.slice(1, 11);

      item.date_from = firstDay;
      item.date_to = lastDay;
    });
    setRerender(!rerender);
  }, []);

  const validator = () => {
    const validate =
      statement.every((obj) => obj.date_from) *
      statement.every((obj) => obj.date_to) *
      statement.every((obj) => obj.amount);

    if (validate) {
      handleClick();
    } else {
      setInvalid(true);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleClick = () => {
    const data = {
      user_id: id,
      statement_type: 0,
      statements: statement,
    };
    const fetchData = async () => {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/user_statement`, data, {
          headers: { Authorization: "Bearer " + token },
        })
        .then(() => {
          goBack();
        });
    };
    fetchData();
  };

  return (
    <div className="d-flex justify-content-center" style={{ fontSize: 14 }}>
      <div className="card bg-light w-75 m-3">
        <h3 className="card-header">Нийгмийн даатгалаар баталгаажуулах</h3>
        <div className="card-body p-0">
          <form>
            <div className="d-flex justify-content-around align-items-center my-4">
              <h1 className="m-0">1</h1>
              <div className="form-group">
                <label>Эхлэх огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Эхлэх огноо"
                  defaultValue={statement[0].date_from}
                  onChange={(e) =>
                    setStatement([
                      {
                        ...statement[0],
                        date_from: e.target.value,
                      },
                      ...statement.slice(1),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дуусах огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Дуусах огноо"
                  defaultValue={statement[0].date_to}
                  onChange={(e) =>
                    setStatement([
                      {
                        ...statement[0],
                        date_to: e.target.value,
                      },
                      ...statement.slice(1),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дүн</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Дүн"
                  onChange={(e) =>
                    setStatement([
                      {
                        ...statement[0],
                        amount: parseInt(e.target.value),
                      },
                      ...statement.slice(1),
                    ])
                  }
                />
              </div>
            </div>
          </form>
          <form>
            <div className="d-flex justify-content-around align-items-center my-4">
              <h1 className="m-0">2</h1>
              <div className="form-group">
                <label>Эхлэх огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Эхлэх огноо"
                  defaultValue={statement[1].date_from}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 1),
                      {
                        ...statement[1],
                        date_from: e.target.value,
                      },
                      ...statement.slice(2),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дуусах огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Дуусах огноо"
                  defaultValue={statement[1].date_to}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 1),
                      {
                        ...statement[1],
                        date_to: e.target.value,
                      },
                      ...statement.slice(2),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дүн</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Дүн"
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 1),
                      {
                        ...statement[1],
                        amount: parseInt(e.target.value),
                      },
                      ...statement.slice(2),
                    ])
                  }
                />
              </div>
            </div>
          </form>
          <form>
            <div className="d-flex justify-content-around align-items-center my-4">
              <h1 className="m-0">3</h1>
              <div className="form-group">
                <label>Эхлэх огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Эхлэх огноо"
                  defaultValue={statement[2].date_from}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 2),
                      {
                        ...statement[2],
                        date_from: e.target.value,
                      },
                      ...statement.slice(3),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дуусах огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Дуусах огноо"
                  defaultValue={statement[2].date_to}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 2),
                      {
                        ...statement[2],
                        date_to: e.target.value,
                      },
                      ...statement.slice(3),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дүн</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Дүн"
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 2),
                      {
                        ...statement[2],
                        amount: parseInt(e.target.value),
                      },
                      ...statement.slice(3),
                    ])
                  }
                />
              </div>
            </div>
          </form>
          <form>
            <div className="d-flex justify-content-around align-items-center my-4">
              <h1 className="m-0">4</h1>
              <div className="form-group">
                <label>Эхлэх огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Эхлэх огноо"
                  defaultValue={statement[3].date_from}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 3),
                      {
                        ...statement[3],
                        date_from: e.target.value,
                      },
                      ...statement.slice(4),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дуусах огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Дуусах огноо"
                  defaultValue={statement[3].date_to}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 3),
                      {
                        ...statement[3],
                        date_to: e.target.value,
                      },
                      ...statement.slice(4),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дүн</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Дүн"
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 3),
                      {
                        ...statement[3],
                        amount: parseInt(e.target.value),
                      },
                      ...statement.slice(4),
                    ])
                  }
                />
              </div>
            </div>
          </form>
          <form>
            <div className="d-flex justify-content-around align-items-center my-4">
              <h1 className="m-0">5</h1>
              <div className="form-group">
                <label>Эхлэх огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Эхлэх огноо"
                  defaultValue={statement[4].date_from}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 4),
                      {
                        ...statement[4],
                        date_from: e.target.value,
                      },
                      ...statement.slice(5),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дуусах огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Дуусах огноо"
                  defaultValue={statement[4].date_to}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 4),
                      {
                        ...statement[4],
                        date_to: e.target.value,
                      },
                      ...statement.slice(5),
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дүн</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Дүн"
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 4),
                      {
                        ...statement[4],
                        amount: parseInt(e.target.value),
                      },
                      ...statement.slice(5),
                    ])
                  }
                />
              </div>
            </div>
          </form>
          <form>
            <div className="d-flex justify-content-around align-items-center my-4">
              <h1 className="m-0">6</h1>
              <div className="form-group">
                <label>Эхлэх огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Эхлэх огноо"
                  defaultValue={statement[5].date_from}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 5),
                      {
                        ...statement[5],
                        date_from: e.target.value,
                      },
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дуусах огноо</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Дуусах огноо"
                  defaultValue={statement[5].date_to}
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 5),
                      {
                        ...statement[5],
                        date_to: e.target.value,
                      },
                    ])
                  }
                />
              </div>
              <div className="form-group">
                <label>Дүн</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Дүн"
                  onChange={(e) =>
                    setStatement([
                      ...statement.slice(0, 5),
                      {
                        ...statement[5],
                        amount: parseInt(e.target.value),
                      },
                    ])
                  }
                />
              </div>
            </div>
          </form>
          {invalid ? (
            <i className="text-danger mx-2">
              Бүх нүдийг тус бүрд нь бөглөнө үү.
            </i>
          ) : (
            <></>
          )}
          <div>
            <button
              onClick={validator}
              type="button"
              className="btn btn-primary btn-sm m-1"
            >
              Баталгаажуулах
            </button>
            <button
              onClick={goBack}
              type="button"
              className="btn btn-secondary btn-sm m-1"
            >
              Буцах
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceStatement;
