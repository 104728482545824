import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import axios from "axios";

const Container = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    margin-top: 10px;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

function Table({ columns, data, pageCount }) {
  const { headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      pageCount,
      manualPagination: true,
    },
    usePagination
  );

  return (
    <>
      <table>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const LoanComment = () => {
  const [searchParams] = useSearchParams();
  const publicId = searchParams.get("query");
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Зээлийн id",
        accessor: "loan_id",
      },
      {
        Header: "Үүсгэсэн хэрэглэгч",
        accessor: "created_by",
      },
      {
        Header: "Үүсгэсэн огноо",
        accessor: (d) => d?.created_at?.replace("T", " "),
      },
      {
        Header: "Холбогдсон cтатус",
        accessor: "connection_status",
        Cell: (params) => {
          const connection_status = params?.row?.original?.connection_status;
          return (
            <div>
              {connection_status === 0
                ? "Утсаа авсан"
                : connection_status === 1
                ? "Утсаа аваагүй"
                : connection_status === 2
                ? "Холбогдохгүй боломжгүй"
                : connection_status === 3
                ? "Утсаа тасалсан"
                : connection_status === 4
                ? "Ашиглалтанд байхгүй"
                : connection_status === 5
                ? "Ярианы эрх хаагдсан"
                : connection_status === 6
                ? "Өөр хүн авсан"
                : null}
            </div>
          );
        },
      },
      {
        Header: "Зурвас",
        accessor: "text",
      },
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/comments?user_public_id=${publicId}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      setData(response.data);
    };
    fetchData();
  }, [publicId, token]);

  return (
    <>
      <div className="d-flex" style={{ fontSize: 14 }}>
        <div className="card bg-light w-100 m-3">
          <h3 className="card-header">Тухайн Зээлийн Комментууд</h3>
          <div className="card-body p-0">
            <Container>
              <Table columns={columns} data={data ? data : []} />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanComment;
