import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

const LoanPayment = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("query");
  const token = localStorage.getItem("token");
  const [banks, setBanks] = useState();

  const date = new Date().toISOString().slice(0, 10);
  const paymentType = [
    {
      name: "Хэтэвчнээс",
      type: 0,
    },
    { name: "Данснаас", type: 1 },
  ];

  const [data, setData] = useState({
    loan_id: id,
    payment_type: 0,
    amount: 0,
    to_bank_code: "",
    from_account_number: "",
    from_bank_code: "",
    payment_date: date,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`https://prod.cashone.mn/banks`);
      setBanks(response.data);
      setData((current) => ({
        ...current,
        from_bank_code: response.data[0].code,
        to_bank_code: response.data[0].code,
      }));
    };
    fetchData();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const handleClick = () => {
    if (data.payment_type === 0) {
      delete data.from_bank_code;
      delete data.from_account_number;
      delete data.to_bank_code;
    }
    const fetchData = async () => {
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/pay_loan`, data, {
          headers: { Authorization: "Bearer " + token },
        })
        .then(() => {
          goBack();
        });
    };
    fetchData();
  };

  return (
    <div className="d-flex justify-content-center" style={{ fontSize: 14 }}>
      <div className="card bg-light w-75 m-3">
        <h3 className="card-header">Зээл төлөх</h3>
        <div className="card-body p-0">
          <div className="p-3">
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Зээлийн дугаар</label>
              <input
                defaultValue={data.loan_id}
                type="number"
                className="form-control"
                placeholder="Зээлийн дугаар"
                onChange={(e) =>
                  setData({ ...data, loan_id: parseInt(e.target.value) })
                }
              />
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Төлбөрийн төрөл</label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) =>
                  setData({ ...data, payment_type: parseInt(e.target.value) })
                }
              >
                {paymentType?.map((type, index) => {
                  return (
                    <option value={type.type} key={index}>
                      {type.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Явуулах банк</label>
              <select
                className="form-select"
                aria-label="Default select example"
                disabled={!data.payment_type}
                onChange={(e) =>
                  setData({ ...data, from_bank_code: e.target.value })
                }
              >
                {banks?.map((bank, index) => {
                  return (
                    <option value={bank.code} key={index}>
                      {bank.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Явуулах данс</label>
              <input
                type="number"
                className="form-control"
                placeholder="Явуулах данс"
                disabled={!data.payment_type}
                onChange={(e) =>
                  setData({ ...data, from_account_number: e.target.value })
                }
              />
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Хүлээн авах банк</label>
              <select
                className="form-select"
                aria-label="Default select example"
                disabled={!data.payment_type}
                onChange={(e) =>
                  setData({ ...data, to_bank_code: e.target.value })
                }
              >
                {banks?.map((bank, index) => {
                  return (
                    <option value={bank.code} key={index}>
                      {bank.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Дүн</label>
              <input
                type="number"
                className="form-control"
                placeholder="Дүн"
                onChange={(e) =>
                  setData({ ...data, amount: parseInt(e.target.value) })
                }
              />
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Төлсөн огноо</label>
              <input
                type="date"
                className="form-control"
                defaultValue={data.payment_date}
                onChange={(e) =>
                  setData({ ...data, payment_date: e.target.value })
                }
              />
            </div>
            <button
              onClick={handleClick}
              type="button"
              className="btn btn-primary w-100"
            >
              Төлөх
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanPayment;
