import React, { useEffect, useState, useMemo } from "react";
import axios from "../../axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTable, usePagination } from "react-table";

const Container = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    margin-top: 10px;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Input = styled.input`
  width: 100%;
  max-width: 220px;
  height: 15px;
  padding: 12px;
  border-radius: 12px;
  border: 1.5px solid lightgrey;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;

  :hover {
    border: 2px solid lightgrey;
    box-shadow: 0px 0px 20px -17px;
  }

  :active {
    transform: scale(0.95);
  }

  :focus {
    border: 2px solid grey;
  }
`;

const Button = styled.button`
  background-color: #ffffff;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  color: #111827;
  font-family: ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;

  :hover {
    background-color: #f9fafb;
  }

  :focus {
    outline: 2px solid rgba(0, 0, 0, 0.1);
    outline-offset: 2px;
  }

  :focus-visible {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

function Table({
  columns,
  data,
  pageCount,
  setPage,
  searchQuery,
  setSearchQuery,
}) {
  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      pageCount,
      manualPagination: true,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  useEffect(() => {
    setPage(pageIndex + 1);
  }, [pageIndex, setPage]);

  return (
    <>
      <div>
        <Input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search by name"
        />
      </div>
      <table>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div>
    </>
  );
}

function Users() {
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Нэр",
        accessor: "detail.first_name",
      },
      {
        Header: "Овог",
        accessor: "detail.last_name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Утас",
        accessor: "phone_number",
      },
      {
        Header: "Улс",
        accessor: "country",
      },
      {
        Header: "Регистер №",
        accessor: "register_number",
      },
      {
        Header: "Дэлгэрэнгүй",
        accessor: "more",
        Cell: (params) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to={{
                  pathname: `/detail`,
                  search: `query=${params.row.original.public_id}`,
                }}
                type="button"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <Button>Дэлгэрэнгүй</Button>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/users?page=${page}&query=${searchQuery}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      setUsers(response.data.users);
      setTotalPages(response.data.pages);
    };
    fetchData();
  }, [page, searchQuery, token]);

  return (
    <Container>
      <Table
        columns={columns}
        data={users ? users : []}
        pageCount={totalPages}
        setPage={setPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    </Container>
  );
}

export default Users;
