import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";

const EditInfo = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("query");
  const token = localStorage.getItem("token");

  return (
    <div className="d-flex justify-content-center" style={{ fontSize: 14 }}>
      <div className="card bg-light w-100 m-3">
        <h3 className="card-header">Мэдээлэл засах</h3>
        <div className="card-body p-0">
          <div className="p-3">
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Зээлийн дугаар</label>
              <input
                type="number"
                className="form-control"
                placeholder="Зээлийн дугаар"
              />
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Дүн</label>
              <input type="number" className="form-control" placeholder="Дүн" />
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Дүн</label>
              <input type="number" className="form-control" placeholder="Дүн" />
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Дүн</label>
              <input type="number" className="form-control" placeholder="Дүн" />
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Дүн</label>
              <input type="number" className="form-control" placeholder="Дүн" />
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Дүн</label>
              <input type="number" className="form-control" placeholder="Дүн" />
            </div>
            <div className="d-flex flex-column w-100 mb-3">
              <label className="mb-1">Дүн</label>
              <input type="number" className="form-control" placeholder="Дүн" />
            </div>
            <button
              onClick={() => console.log("Edit info")}
              type="button"
              className="btn btn-primary w-100"
            >
              Засах
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditInfo;
