import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import axios from "axios";
import "./styles.css";
import ContractModal from "../../components/Modal/ContractModal";

const Detail = () => {
  const [searchParams] = useSearchParams();
  const publicId = searchParams.get("query");
  const token = localStorage.getItem("token");
  const role = parseInt(localStorage.getItem("role"));
  const [id, setId] = useState();
  const [data, setData] = useState();
  const [image, setImage] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user_info?public_id=${publicId}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      setId(response.data.user.id);
      setData(response.data);
      setImage([
        { title: "Back image", url: response.data.user.back_image },
        { title: "Front image", url: response.data.user.front_image },
        { title: "Selfie image", url: response.data.user.selfie_image },
      ]);
    };
    fetchData();
  }, [token, publicId]);

  return (
    <div style={{ fontSize: 14 }}>
      <div>
        <div className="d-flex">
          <Link
            to={{ pathname: `/active-loan`, search: `query=${publicId}` }}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <button type="button" className="btn btn-secondary btn-sm m-1">
              Идэвхитэй зээл
            </button>
          </Link>
          <Link
            to={{ pathname: `/insurance-statement`, search: `query=${id}` }}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <button type="button" className="btn btn-secondary btn-sm m-1">
              НД-р орлого баталгаажуулах
            </button>
          </Link>
          <Link
            to={{ pathname: `/bank-statement`, search: `query=${id}` }}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <button type="button" className="btn btn-secondary btn-sm m-1">
              Дансаар орлого баталгаажуулах
            </button>
          </Link>
          <Link
            to={{ pathname: `/user-device`, search: `query=${id}` }}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <button type="button" className="btn btn-secondary btn-sm m-1">
              Нэвтрэлт
            </button>
          </Link>
          {role ? (
            <></>
          ) : (
            <Link
              to={{ pathname: `/edit-info`, search: `query=${id}` }}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <button type="button" className="btn btn-secondary btn-sm m-1">
                Мэдээлэл засах
              </button>
            </Link>
          )}
          <Link
            to={{ pathname: `/loan-history`, search: `query=${publicId}` }}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <button type="button" className="btn btn-secondary btn-sm m-1">
              Зээлийн түүхүүд
            </button>
          </Link>
          {role ? (
            <></>
          ) : (
            <Link
              to={{ pathname: `/pocket`, search: `query=${publicId}` }}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <button type="button" className="btn btn-secondary btn-sm m-1">
                Pocket
              </button>
            </Link>
          )}
          {data?.user?.has_contract ? (
            <button
              type="button"
              className="btn btn-danger btn-sm m-1"
              onClick={() => {
                if (window.confirm("Гэрээ устгах уу?")) {
                  axios
                    .delete(
                      `${process.env.REACT_APP_BASE_URL}/contract?user_id=${id}`,
                      { headers: { Authorization: "Bearer " + token } }
                    )
                    .then(() => {
                      setTimeout(() => {
                        window.location.reload();
                      }, "2000");
                    });
                }
              }}
            >
              Гэрээ устгах
            </button>
          ) : (
            <ContractModal user_id={id} />
          )}
        </div>
        <div className="image-container">
          <img src={data?.user?.back_image} onClick={() => setIsOpen(true)} />
          <img src={data?.user?.front_image} onClick={() => setIsOpen(true)} />
          <img src={data?.user?.selfie_image} onClick={() => setIsOpen(true)} />
          {isOpen && (
            <Lightbox
              imageTitle={image[imgIndex].title}
              imageCaption={image[imgIndex].caption}
              mainSrc={image[imgIndex].url}
              nextSrc={image[(imgIndex + 1) % image.length].url}
              prevSrc={image[(imgIndex + image.length - 1) % image.length].url}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setImgIndex((imgIndex + image.length - 1) % image.length)
              }
              onMoveNextRequest={() =>
                setImgIndex((imgIndex + 1) % image.length)
              }
            />
          )}
        </div>
      </div>
      <div className="d-flex">
        <div className="card bg-light mb-3 w-100 m-1">
          <div className="card-header">Хэрэглэгчийн Дэлгэрэнгүй</div>
          <div className="card-body p-0">
            <div
              className="scrollable"
              style={{ overflowY: "auto", maxHeight: 250 }}
            >
              <div className="d-flex justify-content-between m-1">
                <div>User ID:</div>
                <div>{data?.user?.id}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Гэрээний дугаар:</div>
                <div>{}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Овог:</div>
                <div>{data?.user?.detail?.last_name}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Нэр:</div>
                <div>{data?.user?.detail?.first_name}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Регистрийн дугаар:</div>
                <div>{data?.user?.register_number}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Хүйс:</div>
                <div>{data?.user?.gender ? "Эрэгтэй" : "Эмэгтэй"}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Утасны дугаар:</div>
                <div>{data?.user?.phone_number}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Цахим хаяг:</div>
                <div>{data?.user?.email}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Баталгаажсан дүн:</div>
                <div>{data?.application_form?.verified_monthly_income}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Сүүлд шинэчилсэн огноо:</div>
                <div>{}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-light mb-3 w-100 m-1">
          <div className="card-header">Холбоо барих</div>
          <div className="card-body p-0">
            <div
              className="scrollable"
              style={{ overflowY: "auto", maxHeight: 250 }}
            >
              <div className="d-flex justify-content-between m-1">
                <div>Хаяг:</div>
                <div>{data?.user?.detail?.address}</div>
              </div>
              <hr className="m-0" />
              <p className="text-danger m-1">Холбоо барих хүний мэдээлэл</p>
              <div className="d-flex justify-content-between m-1">
                <div>Нэр:</div>
                <div>{data?.user?.detail?.emergency_full_name}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Утас:</div>
                <div>{data?.user?.detail?.emergency_phone}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Холбоо хамаарал:</div>
                <div>{data?.user?.detail?.emergency_relationship}</div>
              </div>
              <hr className="m-0" />
              <p className="text-danger m-1">
                Гол холбоо барих хүн (гэр бүлийн)
              </p>
              <div className="d-flex justify-content-between m-1">
                <div>Нэр:</div>
                <div>{data?.user?.detail?.family_full_name}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Утас:</div>
                <div>{data?.user?.detail?.family_phone}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Холбоо хамаарал:</div>
                <div>{data?.user?.detail?.family_relationship}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-light mb-3 w-100 m-1">
          <div className="card-header">Хэрэглэгчийн оноо</div>
          <div className="card-body p-0">
            <div
              className="scrollable"
              style={{ overflowY: "auto", maxHeight: 300 }}
            >
              <div className="d-flex justify-content-between m-1">
                <div>Final score:</div>
                <div>{data?.score?.score}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Final grade:</div>
                <div>{data?.score?.name}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>ANKET TYPE:</div>
                <div>{data?.application_form?.type}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="card bg-light mb-3 w-100 m-1">
          <div className="card-header">Санхүүгийн мэдээлэл</div>
          <div className="card-body p-0">
            <div
              className="scrollable"
              style={{ overflowY: "auto", maxHeight: 300 }}
            >
              <div className="d-flex justify-content-between m-1">
                <div>Орлогын эх үүсвэр:</div>
                <div>
                  {data?.application_form?.revenue_source === 0
                    ? "Цалин"
                    : data?.application_form?.revenue_source === 1
                    ? "Бизнес"
                    : data?.application_form?.revenue_source === 2
                    ? "Цалин + Бизнес"
                    : data?.application_form?.revenue_source === 3
                    ? "Цалин + бусад орлого"
                    : data?.application_form?.revenue_source === 4
                    ? "Тэтгэвэр"
                    : data?.application_form?.revenue_source === 5
                    ? "Тэтгэмж, халамж"
                    : data?.application_form?.revenue_source === 6
                    ? "Орлого байхгүй"
                    : data?.application_form?.revenue_source === 7
                    ? "Ажлын хөлс, цалин төст орлого"
                    : data?.application_form?.revenue_source === 8
                    ? "Бусад"
                    : null}
                </div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Сарын нийт орлогын дүн:</div>
                <div>{data?.application_form?.monthly_income}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Системээс тооцсон хэрэглэгчийн орлого:</div>
                <div>{data?.primarily_income}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Хэрэглэгчийн НДШ-р баталгаажсан орлого:</div>
                <div>{data?.tax_income}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Идэвхтэй зээл байгаа эсэх:</div>
                <div>
                  {data?.has_car_loan ||
                  data?.has_consumer_loan ||
                  data?.has_mobile_phone_loan ||
                  data?.has_mortgage_loan ||
                  data?.has_salary_loan
                    ? "Тийм"
                    : "Үгүй"}
                </div>
              </div>
              <hr className="m-0" />
              <p className="text-danger m-1">Ажил эрхлэлт</p>
              <div className="d-flex justify-content-between m-1">
                <div>Ажил эрхлэлтийн төрөл:</div>
                <div>
                  {data?.application_form?.employment_type === 0
                    ? "Бүтэн цагийн ажилтан"
                    : data?.application_form?.employment_type === 1
                    ? "Хувиараа хөдөлмөр эрхлэгч"
                    : data?.application_form?.employment_type === 2
                    ? "Бизнес эрхлэгч"
                    : data?.application_form?.employment_type === 3
                    ? "Цагийн ажилтан"
                    : data?.application_form?.employment_type === 4
                    ? "Оюутан"
                    : data?.application_form?.employment_type === 5
                    ? "Ажилгүй"
                    : null}
                </div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Ажилладаг байгууллагын хэлбэр:</div>
                <div>
                  {data?.application_form?.organization_type === 0
                    ? "Хувийн хэвшил, бизнес"
                    : data?.application_form?.organization_type === 1
                    ? "Төрийн өмчит компани"
                    : data?.application_form?.organization_type === 2
                    ? "Төсвийн, төрийн үйлчилгээ"
                    : data?.application_form?.organization_type === 3
                    ? "Төрийн удирдлага"
                    : data?.application_form?.organization_type === 4
                    ? "ТББ"
                    : data?.application_form?.organization_type === 5
                    ? "Бусад"
                    : null}
                </div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Үйл ажиллагааны салбар:</div>
                <div>
                  {data?.application_form?.organization_sector === 0
                    ? "Барилга"
                    : data?.application_form?.organization_sector === 1
                    ? "Бөөний болон жижиглэн худалдаа"
                    : data?.application_form?.organization_sector === 2
                    ? "Авто тээвэр"
                    : data?.application_form?.organization_sector === 3
                    ? "Хүнсний үйлдвэрлэл"
                    : data?.application_form?.organization_sector === 4
                    ? "Уул уурхай олборлолт"
                    : data?.application_form?.organization_sector === 5
                    ? "Зочид буудал, нийтийн хоол"
                    : data?.application_form?.organization_sector === 6
                    ? "Агаарын тээвэр"
                    : data?.application_form?.organization_sector === 7
                    ? "Агуулахын үйл ажиллагаа"
                    : data?.application_form?.organization_sector === 8
                    ? "Батлан хамгаалах"
                    : data?.application_form?.organization_sector === 9
                    ? "Боловсрол, шинжлэх ухаан"
                    : data?.application_form?.organization_sector === 10
                    ? "Боловсруулах үйлдвэрлэл"
                    : data?.application_form?.organization_sector === 11
                    ? "Загас барилт, ан агнуур"
                    : data?.application_form?.organization_sector === 12
                    ? "Менежмент зөвлөгөө"
                    : data?.application_form?.organization_sector === 13
                    ? "Мэдээлэл, харилцаа холбоо"
                    : data?.application_form?.organization_sector === 14
                    ? "Нийтлэл зохиол"
                    : data?.application_form?.organization_sector === 15
                    ? "Ойн аж ахуй"
                    : data?.application_form?.organization_sector === 16
                    ? "Олон улсын байгууллага"
                    : data?.application_form?.organization_sector === 17
                    ? "Өрхийн үйлдвэрлэл"
                    : data?.application_form?.organization_sector === 18
                    ? "Санхүү болон даатгал"
                    : data?.application_form?.organization_sector === 19
                    ? "Улс төр"
                    : data?.application_form?.organization_sector === 20
                    ? "Урлаг соёл, спорт"
                    : data?.application_form?.organization_sector === 21
                    ? "Ус хангамж"
                    : data?.application_form?.organization_sector === 22
                    ? "Үзвэр үйлчилгээ"
                    : data?.application_form?.organization_sector === 23
                    ? "Үл хөдлөх хөрөнгө"
                    : data?.application_form?.organization_sector === 24
                    ? "Хууль эрх зүй"
                    : data?.application_form?.organization_sector === 25
                    ? "Хог, хаягдлын менежмент"
                    : data?.application_form?.organization_sector === 26
                    ? "Хөдөө аж ахуй"
                    : data?.application_form?.organization_sector === 27
                    ? "Хөрөнгө оруулалт"
                    : data?.application_form?.organization_sector === 28
                    ? "Хувцас загвар"
                    : data?.application_form?.organization_sector === 29
                    ? "Хэвлэл мэдээлэл"
                    : data?.application_form?.organization_sector === 30
                    ? "Шашины ба буяны байгууллага"
                    : data?.application_form?.organization_sector === 31
                    ? "Эрүүл мэнд"
                    : data?.application_form?.organization_sector === 32
                    ? "Эрчим хүч"
                    : null}
                </div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Нийт ажил эрхэлсэн байгууллагын тоо:</div>
                <div>{}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Ажлын газрын нэр:</div>
                <div>{data?.application_form?.organization_name}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Албан тушаал:</div>
                <div>{data?.application_form?.job_position}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Тухайн байгууллагад ажилласан жил:</div>
                <div>
                  {data?.application_form?.service_length === 0
                    ? "0-6 сар"
                    : data?.application_form?.service_length === 1
                    ? "6-12 сар"
                    : data?.application_form?.service_length === 2
                    ? "1-3 жил"
                    : data?.application_form?.service_length === 3
                    ? "3-5 жил"
                    : data?.application_form?.service_length === 4
                    ? "6-аас дээш"
                    : null}
                </div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>НДШ төлдөг эсэх:</div>
                <div>
                  {data?.application_form?.social_insurance_duration === 0
                    ? "Үгүй"
                    : "Тийм"}
                </div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>НДШ төлсөн хугацаа:</div>
                <div>
                  {data?.application_form?.social_insurance_duration === 0
                    ? "Төлдөггүй"
                    : data?.application_form?.social_insurance_duration === 1
                    ? "0-6 сар"
                    : data?.application_form?.social_insurance_duration === 2
                    ? "6-12 сар"
                    : data?.application_form?.social_insurance_duration === 3
                    ? "1-3 жил"
                    : data?.application_form?.social_insurance_duration === 4
                    ? "3-5 жил"
                    : data?.application_form?.social_insurance_duration === 5
                    ? "6-10 жил"
                    : data?.application_form?.social_insurance_duration === 6
                    ? "10-аас дээш"
                    : null}
                </div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Таны НДШ суутгуулан авдаг орлогын хэмжээ:</div>
                <div>{data?.application_form?.salary}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-light mb-3 w-100 m-1">
          <div className="card-header">Дансны мэдээлэл</div>
          <div className="card-body p-0">
            <div
              className="scrollable"
              style={{ overflowY: "auto", maxHeight: 300 }}
            >
              {data?.bank_accounts?.map((item, index) => {
                return (
                  <div key={index}>
                    <p className="text-danger m-1">
                      {item?.is_primary ? "Үндсэн данс" : "Бусад данс"}
                    </p>
                    <div className="d-flex justify-content-between m-1">
                      <div>Банк:</div>
                      <div>{item?.bank?.name}</div>
                    </div>
                    <hr className="m-0" />
                    <div className="d-flex justify-content-between m-1">
                      <div>Дансны дугаар:</div>
                      <div>{item?.number}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="card bg-light mb-3 w-100 m-1">
          <div className="card-header">Хэрэглэгчийн зээлийн эрх</div>
          <div className="card-body p-0">
            <div
              className="scrollable"
              style={{ overflowY: "auto", maxHeight: 300 }}
            >
              <div className="d-flex justify-content-between m-1">
                <div>Нийт зээлийн эрх:</div>
                <div>{data?.loan_capacity?.total_limit}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Боломжит зээлийн дүн:</div>
                <div>{data?.loan_capacity?.current_limit}</div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Олгосон зээл:</div>
                <div>
                  {isNaN(
                    data?.loan_capacity?.total_limit -
                      data?.loan_capacity?.current_limit
                  )
                    ? null
                    : data?.loan_capacity?.total_limit -
                      data?.loan_capacity?.current_limit}
                </div>
              </div>
              <hr className="m-0" />
              <div className="d-flex justify-content-between m-1">
                <div>Баланс:</div>
                <div>{data?.balance}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detail;
